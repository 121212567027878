<template>
  <div ref="docuDiv" class="vg_wrapper">
    <span v-if="showSelectedFileSize"> 所选文件总大小:{{ totalSizeOfSelectedFiles }} </span>
    <el-card>
      <el-form :disabled="downloadProgressVisible">
        <el-row class="vd_posi">
          <el-col :md="24">
            <el-form-item>
              <el-upload
                v-if="!specialForm && showBtn"
                ref="upload"
                :action="uploadUrl"
                :before-upload="beforeUpload"
                :data="uploadData"
                :disabled="!btn.add"
                :on-success="handleSuccess"
                :limit="10"
                :on-exceed="handleExceed"
                class="upload-demo"
                multiple
              >
                <el-button slot="trigger" :disabled="!btn.add || downloadProgressVisible" size="small" type="primary">上传</el-button>
                <el-button :disabled="!btn.add || downloadProgressVisible" :type="'success'" size="small" @click="downClick">批量下载并压缩</el-button>
                <el-button :disabled="!btn.add || downloadProgressVisible" :type="'success'" size="small" @click="downClickNoZip">批量下载不压缩</el-button>
                <el-button :disabled="!btn.delete" size="small" type="danger" @click="manyDeleteClick">批量删除</el-button>
                <div slot="tip" class="el-upload__tip" style="font-size: 24px">
                  单个文件大小不得超过<span style="color: red; font-weight: 600">300MB</span>!
                </div>
              </el-upload>
              <div v-if="specialForm" style="display: flex; margin-bottom: 8px">
                <el-button :disabled="downloadProgressVisible" :type="'warning'" size="small" @click="downClick">批量下载</el-button>
                <el-button size="small" type="success" @click="syncDocu">同步至工厂打样单</el-button>
                <el-button size="small" type="primary" @click="initData()">刷新</el-button>
                <el-input
                  v-model="specialForm.modify_remarks"
                  :rows="1"
                  class="vg_ml_8"
                  clearable
                  maxlength="200"
                  placeholder="请填写修改备注"
                  size="small"
                  type="textarea"
                ></el-input>
              </div>
              <el-progress
                v-if="downloadProgressVisible"
                :format="val => `正在下载...${val}%`"
                :percentage="downloadPercentage"
                :status="downloadStatus"
                :stroke-width="26"
                :text-color="'#ffffff'"
                :text-inside="true"
                class="vg_mb_8"
              ></el-progress>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table ref="docuList" :data="tableData" border @selection-change="handleSelectionChange">
            <el-table-column align="center" type="selection" width="55"></el-table-column>
            <el-table-column align="center" label="序号" width="60">
              <template v-slot="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="文件名" prop="docu_name" sortable />
            <el-table-column label="文件格式" prop="docu_suffix" />
            <el-table-column label="文件大小" prop="docu_size" />
            <el-table-column label="上传人" prop="uploaded_personnel" />
            <el-table-column :formatter="({ create_time }) => getDate(create_time, true)" label="上传时间" prop="create_time" />
            <el-table-column align="center" label="操作" width="120">
              <template v-slot="scope">
                <el-row justify="space-around" type="flex">
                  <el-col :md="8">
                    <el-link class="vg_cursor" type="success" @click="download(scope.row)">下载</el-link>
                  </el-col>
                  <el-col v-if="stateFlag !== 2 && !specialForm" :md="8">
                    <el-link :disabled="!btn.delete" class="vg_cursor" type="danger" @click="remove(scope.$index, scope.row)">删除</el-link>
                  </el-col>
                  <el-col :md="8">
                    <el-link v-if="imgarr.indexOf(scope.row.docu_suffix) !== -1" class="vg_cursor" type="warning" @click="seeShow(scope.row)">查看</el-link>
                    <el-link v-if="'pdf' === scope.row.docu_suffix" class="vg_ml_8 vg_pointer" @click="openPDF(scope.row.docu_url)">查看</el-link>
                    <el-link v-if="officeFileSuffixList.includes(scope.row.docu_suffix)" class="vg_ml_8 vg_pointer" @click="openOffice(scope.row.docu_url)"
                      >查看
                    </el-link>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-form>
    </el-card>
    <el-dialog :visible.sync="dialogVisible" append-to-body class="showImg" title="图片查看" width="50%">
      <el-image class="showImg" :preview-src-list="[showImgUrl]" :src="showImgUrl" fit="contain"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { docuAPI } from '@api/modules/docu';
import helper from '@assets/js/helper';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { getDate } from '@assets/js/dateUtils';
import { requAPI } from '@api/modules/requ';
import { cloneDeep } from 'lodash';
import bus from '@/components/common/bus';
import UrlEncode from '@assets/js/UrlEncode';
import { BigNumber } from 'bignumber.js';
import { objectArrayReduce } from '@assets/js/arrayUtils';

export default {
  name: 'docuAbout',
  props: {
    docuAbout: Object,
    stateFlag: {
      default: 0,
      type: Number
    },
    specialForm: {
      default: () => {},
      type: Object
    },
    showBtn: {
      default: true,
      type: Boolean
    },
    showSelectedFileSize: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    'specialForm.requ_smpl_id'(newValue, oldValue) {
      this.initData();
    }
  },
  data() {
    return {
      uploadData: {
        perm_id: this.$route.path.indexOf('edit') !== -1 ? JSON.parse(UrlEncode.decode(this.$route.query.key)).perm_id : this.$route.query.perm_id,
        form_id: ''
      },
      tableData: [],
      uploadUrl: helper.modePrepend(docuAPI.uploadDocuments),
      docuUrl: '',
      docuName: '',
      btn: {},
      dialogVisible: false,
      showImgUrl: '',
      uploadFlag: true,
      beforeFlag: 0,
      successFlag: 0,
      imgarr: [
        'bmp',
        'jpg',
        'png',
        'tif',
        'gif',
        'pcx',
        'tga',
        'exif',
        'fpx',
        'svg',
        'psd',
        'cdr',
        'pcd',
        'dxf',
        'ufo',
        'eps',
        'ai',
        'raw',
        'WMF',
        'webp',
        'avif',
        'apng',
        'jpeg'
      ],
      officeFileSuffixList: [
        'doc',
        'docx',
        'docm',
        'dotm',
        'dotx',
        'xlsx',
        'xlsb',
        'xls',
        'xlsm',
        'pptx',
        'ppsx',
        'ppt',
        'pps',
        'pptm',
        'potm',
        'ppam',
        'potx',
        'ppsm'
      ],
      multipleSelection: [],
      downloadProgressVisible: false,
      downloadPercentage: 0,
      downloadStatus: '', // 进度条状态，例如：'success', 'exception'
      observer: new IntersectionObserver(this.handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // 交叉区域比例
      })
    };
  },
  computed: {
    totalSizeOfSelectedFiles() {
      return new BigNumber(objectArrayReduce(this.multipleSelection, 'meta_data_size')).div(1024).div(1024).toFixed(4) + 'MB';
    }
  },
  mounted() {
    this.observer.observe(this.$refs.docuDiv);
    this.initData();
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) this.initData();
      });
    },
    getDate,
    initData() {
      this.getDocus();
    },
    async openPDF(url) {
      window.open(helper.megPath(url));
    },
    openOffice(url) {
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + helper.megPath(url));
    },
    beforeUpload(file) {
      let fileName = file.name.substr(0, file.name.lastIndexOf('.'));
      if (fileName.length > 500) {
        this.$message.warning('文件名字过长! 长度不可大于500!');
        return false;
      }
      if (file.name === ' ' || file.name === null) {
        this.$message.warning('文件名字不能为空');
        return false;
      }
      if (file.size === 0) {
        this.$message.warning('不能上传空文件');
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 < 300;
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.uploadData.perm_id = props.perm_id;
      this.uploadData.form_id = this.specialForm?.requ_smpl_id || props.form_id;
      if (!isLt5M) {
        this.$message.error('文件大小不得超过 300MB!');
      }
      this.beforeFlag++;
      return isLt5M;
    },
    handleSuccess(val) {
      if (val.code === 2) {
        this.$message({
          type: 'error',
          message: '上传文件不能为空文件！'
        });
      } else if (val.code === 999) {
        this.$message({
          type: 'error',
          message: val.msg
        });
      }
      this.successFlag++;
      if (this.successFlag === this.beforeFlag) {
        setTimeout(() => {
          this.$refs.upload.clearFiles(); //去掉文件列表
          this.successFlag = 0;
          this.beforeFlag = 0;
        }, 500);
      }
      this.initData();
    },
    //获取相关文档列表
    getDocus() {
      const props = this.$route.path.indexOf('add') !== -1 ? this.$route.query : JSON.parse(UrlEncode.decode(this.$route.query.key));
      let params = {};
      if (this.specialForm) {
        if (this.specialForm.group_type === 0 && !this.specialForm.designTeam_id) {
          this.tableData = [];
          return this.$message.warning('请先关联产品设计组后在操作!');
        }
        params = {
          perm_id: this.specialForm.group_type === 0 ? 209 : 113,
          form_id: this.specialForm.group_type === 0 ? this.specialForm.designTeam_id : this.specialForm.desi_id
        };
      } else {
        params = {
          perm_id: props.perm_id,
          form_id: props.form_id
        };
      }
      get(docuAPI.getDocus, params)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            if (!this.specialForm) this.btn = res.data.data.btn;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          console.log('Error', res);
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量删除
    manyDeleteClick() {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let temp = [];
          for (let i = 0; i < this.multipleSelection.length; i++) {
            temp.push(this.multipleSelection[i].docu_pter_id);
          }
          let ids = temp.join(',');
          post(docuAPI.destroyDocuPtersByIds, { docu_pter_ids: ids })
            .then(res => {
              if (res.data.code === 0) {
                this.initData();
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            });
        })
        .catch(() => {});
    },
    //批量下载不压缩
    downClickNoZip() {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      this.multipleSelection.forEach((item, index) => {
        setTimeout(() => {
          this.download(item);
        }, index * 150);
      });
      this.$refs.docuList.clearSelection();
    },
    //批量下载并压缩
    downClick() {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      this.downloadProgressVisible = true; // 显示进度条
      this.downloadPercentage = 0;
      this.downloadStatus = null;
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      let companyAttachmentsList = [];

      for (let i = 0; i < this.multipleSelection.length; i++) {
        let docuUrl = this.helper.megPath(this.multipleSelection[i].docu_url);
        companyAttachmentsList.push(docuUrl);
      }

      // 添加进度追踪逻辑
      let totalTasks = companyAttachmentsList.length;
      let completedTasks = 0;

      companyAttachmentsList.forEach((item, index) => {
        let { docu_name, docu_suffix } = this.multipleSelection[index];
        const promise = this.helper.downloadZip(item).then(data => {
          const arr_name = item.split('/');
          const file_name = docu_name + '.' + docu_suffix;
          zip.file(file_name, data, { binary: true });
          cache[file_name] = data;
          // 更新进度
          completedTasks++;
          this.downloadPercentage = Math.floor((completedTasks / totalTasks) * 100);
        });
        promises.push(promise);
      });

      Promise.all(promises)
        .then(() => {
          zip
            .generateAsync({ type: 'blob' })
            .then(content => {
              saveAs(content, '下载文件包.zip');
              this.downloadProgressVisible = false; // 隐藏进度条
              this.$refs.docuList.clearSelection();
            })
            .catch(error => {
              this.downloadProgressVisible = false; // 遇到错误也隐藏进度条
              console.error('生成zip文件出错:', error);
            });
        })
        .catch(error => {
          this.downloadProgressVisible = false;
          this.downloadStatus = 'exception';
          console.error('下载文件出错:', error);
        });
    },
    //下载
    download(row) {
      this.docuUrl = this.helper.megPath(row.docu_url);
      this.docuName = row.docu_name + '.' + row.docu_suffix;
      this.helper.downloadItemC(this.docuUrl, this.docuName);
    },
    //单行删除
    remove(index, row) {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          post(docuAPI.destroyDocuPterById, { docu_pter_id: row.docu_pter_id })
            .then(res => {
              if (res.data.code === 0) {
                this.initData();
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            });
        })
        .catch(() => {});
    },
    seeShow(row) {
      this.dialogVisible = true;
      this.showImgUrl = helper.megPath(row.docu_url);
    },
    syncDocu() {
      if (this.multipleSelection.length === 0) return this.$message.warning('请选择数据!');
      let requstBody = cloneDeep(this.specialForm);
      requstBody.docuPtersslist = this.multipleSelection;
      requAPI.editSampleDocu(requstBody).then(({ data }) => {
        this.$message.success('同步成功!');
        this.$emit('successSync');
        bus.$emit('refreshRequDocuList');
      });
    },
    handleExceed() {
      this.$message.warning('单次同时上传文件限制为10个!');
    }
  }
};
</script>

<style scoped lang="scss">
.upload-demo {
  display: flex;
  align-items: baseline;
}
.upload-demo ::v-deep .el-upload--text {
  width: 68px;
  height: auto;
  text-align: left;
  border: none;
  border-radius: 0;
  display: flex;
}
.el-upload__tip {
  margin-bottom: 16px;
}
::v-deep .el-dialog__body {
  text-align: center;
}
.vd_dis {
  display: flex;
}
.vd_posi {
  position: relative;
}
.vd_posi_o {
  position: absolute;
  top: 0;
  left: 65px;
}
</style>
